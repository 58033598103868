var winW = $(window).width();
var winH = $(window).height();

$(window).resize(function () {
  var winW = $(window).width();
  var winH = $(window).height();
});

// lottie animation
var animation = bodymovin.loadAnimation({
  container: document.getElementById("whywork01"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/lottie/whywork-01.json",
});

var animation = bodymovin.loadAnimation({
  container: document.getElementById("whywork02"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/lottie/whywork-02.json",
});

var animation = bodymovin.loadAnimation({
  container: document.getElementById("whywork03"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/lottie/whywork-03.json",
});

var animation = bodymovin.loadAnimation({
  container: document.getElementById("contact01"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/lottie/contact-01.json",
});

var animation = bodymovin.loadAnimation({
  container: document.getElementById("animatedValue01"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/lottie/value-codefork.json",
});

var animation = bodymovin.loadAnimation({
  container: document.getElementById("animatedCode02"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/lottie/coding-02.json",
});

var animation = bodymovin.loadAnimation({
  container: document.getElementById("animatedCode03"),
  renderer: "svg",
  loop: true,
  autoplay: true,
  path: "/assets/lottie/coding-03.json",
});

// typewriter
var app = document.getElementById("typeWriter");
var typewriter = new Typewriter(app, {
  loop: true,
  delay: 75,
});
typewriter
  .typeString("Innovative")
  .pauseFor(2000)
  .deleteChars(10)
  .typeString("Future Ready")
  .pauseFor(2000)
  .deleteChars(10)
  .start();

// Number counter
$(".count").each(function () {
  $(this)
    .prop("Counter", 0)
    .animate(
      {
        Counter: $(this).text(),
      },
      {
        duration: 3000,
        easing: "swing",
        step: function (now) {
          now = Number(Math.ceil(now)).toLocaleString("en");
          $(this).text(now);
        },
      }
    );
});

//Feathericon
feather.replace();

//AOS animation
AOS.init();

//swiperJS
let accordionActiveIndex = 0;
$(document).ready(function () {
  //click form to show captcha
  $("#Contact").click(function (e) {
    $(".grecaptcha-badge").addClass("show");
  });

  //scroll to fix nav
  var scroll = $(window).scrollTop();
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    // console.log(scroll);
    if (scroll > 0) {
      $("header").addClass("sticky");
    } else {
      $("header").removeClass("sticky");
    }
  });

  if (scroll > 0) {
    //clearHeader, not clearheader - caps H
    $("header").addClass("sticky");
  } else {
    $("header").removeClass("sticky");
  }

  $(".nav-toggle").click(function (e) {
    e.preventDefault();
    $(".nav-toggle").toggleClass("active");
    $(".nav-menu").toggleClass("active");
    $(".nav-overlay").toggleClass("active");

    $(".nav-menu.active li a").click(function (e) {
      $(".nav-toggle").removeClass("active");
      $(".nav-menu").removeClass("active");
      $(".nav-overlay").removeClass("active");
      // console.log("click");
    });
  });

  $(".nav-overlay").click(function (e) {
    e.preventDefault();
    $(".nav-toggle").toggleClass("active");
    $(".nav-menu").toggleClass("active");
    $(".nav-overlay").toggleClass("active");
  });

  // dataTable
  if ($("#dataTable").length > 0) {
    displayData();
    // $("#dataTable").DataTable();
  }

  if ($("#formSubmit").length > 0) {
    $("#formSubmit").click(function (e) {
      e.preventDefault();
      submitForm();
    });
  }

  if ($(".swiper").length > 0) {
    const swiper = new Swiper(".usageSwiper", {
      slidesPerView: 1.01,
      centeredSlides: true,
      allowTouchMove: false,
      spaceBetween: 30,
      on: {
        slideChangeTransitionStart: function () {
          $(".content--img").hide(0);
          $(".content--img").removeClass("aos-init").removeClass("aos-animate");
        },
        slideChangeTransitionEnd: function () {
          $(".content--img").show(0);
          AOS.init();
        },
      },
    });

    $(".usage__accordion").click(function () {
      const index = $(this).index();
      // console.log(index);
      swiper.slideTo(index, 0);

      if (!$(this).hasClass("active")) {
        $(".usage__accordion.active").removeClass("active");
        $(this).addClass("active");

        if (winW < 639) {
          $(".accordion__content").slideUp("500");
          $(this).find(".accordion__content").slideDown();
        }

        // countdown 5 seconds and trigger click
        let accordionActiveIndex = $(".usage__accordion.active").index();

        // if (winW > 639) {
        //   setTimeout(function () {
        //     if ($(".usage__accordion.active").index() == accordionActiveIndex) {
        //       if (
        //         $(".usage__accordion.active").index() ==
        //         $(".usage__accordion").length - 1
        //       ) {
        //         $(".usage__accordion:first-child").click();
        //       } else {
        //         $(".usage__accordion.active").next().click();
        //         // console.log("next click");
        //       }
        //     }
        //   }, 7000);
        // }
      } else {
        $(this).removeClass("active");
        if (winW < 639) {
          $(this).find(".accordion__content").slideUp(500);
        }
      }
    });

    $(".usage__accordion:first-child").click();
  }
});

function toggleMobileMenu(menu) {
  menu.classList.toggle("open");
}

// Navigation
const navbarMenu = document.getElementById("navbar");

function toggleMenu() {
  navbarMenu.classList.toggle("active");
  overlayMenu.classList.toggle("active");
}

function collapseSubMenu() {
  navbarMenu
    .querySelector(".menu-item-child.active .sub-menu")
    .removeAttribute("style");
  navbarMenu
    .querySelector(".menu-item-child.active")
    .classList.remove("active");
}

if ($("#formPost").length > 0) {
  $("#formPost").click(function (e) {
    e.preventDefault();
    postForm();
  });
}

const submitForm = () => {
  $.getJSON("https://api.ipify.org?format=json", function (data) {
    var userIP = data.ip;

    if (getCookie("acceptCookies") == "true") {
      userIP = data.ip;
    } else {
      userIP = "Rejected cookies";
    }

    var formName = document.getElementById("formName").value;
    var formCompany = document.getElementById("formCompany").value;
    var formEmail = document.getElementById("formEmail").value;
    var formNumber = document.getElementById("formNumber").value;
    var formMessage = document.getElementById("formMessage").value;
    var formSubmitIP = userIP;

    var formNameReady = false;
    var formCompanyReady = false;
    var formEmailReady = false;
    var formNumberReady = false;
    var formMessageReady = false;
    var formCheckedReady = false;

    if (formName == "") {
      $("#formName").addClass("has-error");
      $("#formName").next(".form__validate").addClass("has-error");
      $("#formName").next(".form__validate").html("Please insert your name");
      var formNameReady = false;
    } else if (formName.length < 3) {
      $("#formName").addClass("has-error");
      $("#formName").next(".form__validate").addClass("has-error");
      $("#formName")
        .next(".form__validate")
        .html("Name must be at least 3 characters long");
      var formNameReady = false;
    } else {
      $("#formName").removeClass("has-error");
      $("#formName").next(".form__validate").removeClass("has-error");
      var formNameReady = true;
    }

    if (formCompany == "") {
      $("#formCompany").addClass("has-error");
      $("#formCompany").next(".form__validate").addClass("has-error");
      $("#formCompany")
        .next(".form__validate")
        .html("Please insert your company");
      var formCompanyReady = false;
    } else if (formCompany.length < 5) {
      $("#formCompany").addClass("has-error");
      $("#formCompany").next(".form__validate").addClass("has-error");
      $("#formCompany")
        .next(".form__validate")
        .html("Company must be at least 5 characters long");
      var formCompanyReady = false;
    } else {
      $("#formCompany").removeClass("has-error");
      $("#formCompany").next(".form__validate").removeClass("has-error");
      var formCompanyReady = true;
    }

    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //check personal mail
    var personalmail = /^[^@]+@(yahoo|gmail|hotmail|live|qq|)\.com$/i;

    if (formEmail == "") {
      $("#formEmail").addClass("has-error");
      $("#formEmail").next(".form__validate").addClass("has-error");
      $("#formEmail").next(".form__validate").html("Please insert your email");
      var formEmailReady = false;
    } else if (!re.test(String(formEmail).toLowerCase())) {
      // email validation check
      $("#formEmail").addClass("has-error");
      $("#formEmail").next(".form__validate").addClass("has-error");
      $("#formEmail")
        .next(".form__validate")
        .html("Please insert your valid email");
      var formEmailReady = false;
    } else if (personalmail.test(String(formEmail).toLowerCase())) {
      // email validation check
      $("#formEmail").addClass("has-error");
      $("#formEmail").next(".form__validate").addClass("has-error");
      $("#formEmail")
        .next(".form__validate")
        .html("Only corporate email is accepted");
      var formEmailReady = false;
    } else {
      $("#formEmail").removeClass("has-error");
      $("#formEmail").next(".form__validate").removeClass("has-error");
      var formEmailReady = true;
    }

    if (formNumber == "") {
      $("#formNumber").addClass("has-error");
      $("#formNumber").next(".form__validate").addClass("has-error");
      $("#formNumber")
        .next(".form__validate")
        .html("Please insert your number");
      var formNumberReady = false;
    } else if (formNumber.length < 8) {
      $("#formNumber").addClass("has-error");
      $("#formNumber").next(".form__validate").addClass("has-error");
      $("#formNumber")
        .next(".form__validate")
        .html("Please insert a valid phone number");
      var formNumberReady = false;
    } else {
      $("#formNumber").removeClass("has-error");
      $("#formNumber").next(".form__validate").removeClass("has-error");
      var formNumberReady = true;
    }

    if (formMessage == "") {
      $("#formMessage").addClass("has-error");
      $("#formMessage").next(".form__validate").addClass("has-error");
      $("#formMessage")
        .next(".form__validate")
        .html("Please insert your message");
      var formMessageReady = false;
    } else if (formMessage.length < 15) {
      $("#formMessage").addClass("has-error");
      $("#formMessage").next(".form__validate").addClass("has-error");
      $("#formMessage")
        .next(".form__validate")
        .html("Please insert at least 15 characters");
      var formNumberReady = false;
    } else {
      $("#formMessage").removeClass("has-error");
      $("#formMessage").next(".form__validate").removeClass("has-error");
      var formMessageReady = true;
    }

    if ($("#formPdpa").is(":checked")) {
      $("#formPdpa").parent().next().removeClass("has-error");
      var formCheckedReady = true;
    } else {
      $("#formPdpa").parent().next().addClass("has-error");
      var formCheckedReady = false;
    }

    if (
      formCheckedReady &&
      formNameReady &&
      formCompanyReady &&
      formEmailReady &&
      formNumberReady &&
      formMessageReady
    ) {
      console.log("client success");

      // Reset the form
      // document.getElementById("contactForm").reset();
      // console.log("Data saved");

      // Save form data to server side
      $.ajax({
        url: "/api/contact/form",
        type: "POST",
        contentType: "application/json",
        // data: JSON.stringify({"viewedProfiles": viewedProfiles}),
        data: JSON.stringify({
          name: formName,
          company: formCompany,
          email: formEmail,
          phone: formNumber,
          message: formMessage,
          submitIP: formSubmitIP,
        }),
        dataType: "json",
        success: function (result) {
          if (result.sent == "yes") {
            console.log(result);
            contactSuccess();
          } else {
            console.log(result.errors);
            for (var i = 0; i < result.errors.length; i++) {
              if (result.errors[i].param == "name") {
                $("#formName").addClass("has-error");
                $("#formName").next(".form__validate").addClass("has-error");
                $("#formName")
                  .next(".form__validate")
                  .html(result.errors[i].msg);
              } else if (result.errors[i].param == "company") {
                $("#formCompany").addClass("has-error");
                $("#formCompany").next(".form__validate").addClass("has-error");
                $("#formCompany")
                  .next(".form__validate")
                  .html(result.errors[i].msg);
              } else if (result.errors[i].param == "email") {
                $("#formEmail").addClass("has-error");
                $("#formEmail").next(".form__validate").addClass("has-error");
                $("#formEmail")
                  .next(".form__validate")
                  .html(result.errors[i].msg);
              } else if (result.errors[i].param == "phone") {
                $("#formNumber").addClass("has-error");
                $("#formNumber").next(".form__validate").addClass("has-error");
                $("#formNumber")
                  .next(".form__validate")
                  .html(result.errors[i].msg);
              } else if (result.errors[i].param == "message") {
                $("#formMessage").addClass("has-error");
                $("#formMessage").next(".form__validate").addClass("has-error");
                $("#formMessage")
                  .next(".form__validate")
                  .html(result.errors[i].msg);
              } else if (result.errors[i].param == "submitIP") {
                contactIPNotInSG();
              }
            }
          }
        },
        // failure: function (errMsg) {
        //   console.log(errMsg);
        // },
      });
    } else {
      console.log("not success");
    }
  });
};

function contactSuccess() {
  $(".contact__success").addClass("sent-success");
  var animation = bodymovin.loadAnimation({
    container: document.getElementById("contactSuccess"),
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: "/assets/lottie/contact-success.json",
  });
}

function contactIPNotInSG() {
  $(".contact__success").addClass("sent-success");
  $(".contact__success").html(
    '<h3 class="contact__success__title">Sorry, your region is not supported.</h3>'
  );
}

function onSubmit(token) {
  document.getElementById("formSubmit").submit();
}

// Cookie pop out
(function () {
  var cookieAlert = document.querySelector(".cookie-alert");
  var acceptCookies = document.querySelector(".accept-cookies");
  var rejectCookies = document.querySelector(".reject-cookies");

  if (acceptCookies) {
    if (!getCookie("acceptCookies")) {
      cookieAlert.classList.add("show");
    }

    acceptCookies.addEventListener("click", function (e) {
      e.preventDefault();
      setCookie("acceptCookies", true, 180);
      cookieAlert.classList.remove("show");
    });
  }

  if (rejectCookies) {
    rejectCookies.addEventListener("click", function (e) {
      e.preventDefault();
      cookieAlert.classList.remove("show");
      setCookie("acceptCookies", false, 90);
      gaOptout();
    });
  }
})();

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// Google Analytics Disable
// Set to the same value as the web property used on the site
var gaProperty = "G-T75WQBCRCW";

// Disable tracking if the opt-out cookie exists.
var disableStr = "ga-disable-" + gaProperty;
if (document.cookie.indexOf(disableStr + "=true") > -1) {
  window[disableStr] = true;
}

// Opt-out function
function gaOptout() {
  var timeNow = new Date();
  var expireddays = 90;
  timeNow.setTime(timeNow.getTime() + expireddays * 24 * 60 * 60 * 1000);
  var expired = "expires=" + timeNow.toUTCString();

  document.cookie = disableStr + "=true; " + expired + " path=/";
  window[disableStr] = true;
}
